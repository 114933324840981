<template>
  <section class="home-container-app">
    <BaseLoading v-if="isLoading" />
    <div class="row w-100 ml-0 mt-2">
      <div :class="[apresentarBotaoConfiguracoes ? 'col-10' : 'col-12']" class="align-items-start d-flex">
        <h3 class="title text-start mb-0 text-uppercase titulo">
          {{ TituloPagina }}
        </h3>
        <span class="badge badge-secondary d-block float-left" style="margin-top: 6px; margin-left: 6px">
          <a :href="LinkGuiaRapido" title="D\u00favidas sobre o cadastro? Clique aqui." target="_blank"
            ><i class="fas fa-book"></i>
            Como Funciona?
          </a>
        </span>
      </div>

      <div class="col-2 float-right" v-if="apresentarBotaoConfiguracoes">
        <button
          type="button"
          class="btn btn-default float-right"
          v-tooltip.bottom="tooltipConfiguracao"
          @click="acessarConfiguracao"
        >
          <i class="fas fa-cog icone-preferencias"></i>
        </button>
      </div>
    </div>

    <div class="home-container-body gray-scroll" id="card-principal">
      <router-view />
    </div>
  </section>
</template>

<script>
  import loading from '@/common/loading';
  import swal from '@/common/alerts';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import '@/common/filters';
  import VTooltip from 'v-tooltip';
  import Vue from 'vue';

  Vue.use(VTooltip);

  export default {
    name: 'DevolucaoEntrada',

    components: {},

    data() {
      return {
        TituloPagina: 'Devolução de Compras Fácil',
        LinkGuiaRapido: 'https://share.linx.com.br/pages/viewpage.action?pageId=217199588',
      };
    },

    computed: {
      ...mapState({
        isLoading: (state) => state.uiState.isLoading,
      }),

      ...mapGetters('devolucao', {
        ehPrimeiroAcesso: 'ehPrimeiroAcesso',
        iniciado: 'iniciado',
      }),

      apresentarBotaoConfiguracoes() {
        return this.$route.name !== 'DevolucaoConfiguracoes';
      },

      tooltipConfiguracao() {
        const mensagem = 'Configurações';

        return { content: mensagem, ...this.bootstrapConflict };
      },

      bootstrapConflict() {
        return {
          template:
            '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner" style="border-radius: 10px; font-size: 0.8rem"></div></div>',
          arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
          innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
        };
      },
    },

    created() {
      /*if (this.iniciado) {
        this.redirecionarParaProximaPagina();
        return;
      }*/

      loading.exibir();

      this.$store.dispatch('devolucao/iniciar');

      this.$store
        .dispatch('devolucao/obterDadosIniciais')
        .then(async (response) => {
          loading.ocultar();
          await this.redirecionarParaProximaPagina();
        })
        .catch(() => {
          loading.ocultar();
          swal.exibirMensagemErro('Ocorreu um problema.');
          return;
        });
    },

    methods: {
      redirecionarParaProximaPagina() {
        this.$store
          .dispatch('devolucao/validarPreferenciasEmpresa')
          .then((response) => {
            if (response.length > 0) {
              let mensagem = '';
              if (this.ehPrimeiroAcesso) mensagem = 'Este é seu primeiro acesso, você deve selecionar suas preferências!';
              else mensagem = 'As preferências não estão configuradas corretamente. <br /><br />' + response.join('<br />');

              swal.exibirMensagemAlerta(mensagem, (params) => {
                this.$router.push({ name: 'DevolucaoConfiguracoes' });
              });
            } else {
              this.redirecionarDevolucao();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      redirecionarDevolucao() {
        if (this.$route.name === 'DevolucaoEntrada') {
          this.$router.push({ name: 'DevolucaoIdentificacao' });
        }
      },

      acessarConfiguracao() {
        this.$router.push({ name: 'DevolucaoConfiguracoes' });
      },
    },
  };
</script>

<style scoped>
  .home-container-app {
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
  }

  .home-container-header {
    height: fit-content;
  }

  .home-container-body {
    flex-grow: 1;
    overflow: hidden;
  }

  .title {
    cursor: default;
  }
</style>
