import Vue from 'vue';
import { formatarValorMonetario, decimalKeepPlaces } from '@/common/number';
import string from './string';

Vue.filter('percent', function (n) {
  if (!n) return '0,00 %';

  return (
    n
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+,)/g, '$1.') + ' %'
  );
});

Vue.filter('decimal', function (value) {
  if (!value) {
    return '0,00';
  }

  return (
    '' +
    value
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+,)/g, '$1.')
  );
});

Vue.filter('date', function (n) {
  if (!n) return;

  n = new Date(n);
  const isDateValue = n instanceof Date;
  if (!isDateValue) return;

  let day = String(n.getDate());
  let month = String(n.getMonth() + 1);
  let year = n.getFullYear();

  day = day.length === 2 ? day : `0${day}`;
  month = month.length === 2 ? month : `0${month}`;

  return `${day}/${month}/${year}`;
});

Vue.filter('shortDate', function (n) {
  if (!n) return;

  n = new Date(n);
  const isDateValue = n instanceof Date;
  if (!isDateValue) return;

  let day = String(n.getDate());
  let month = String(n.getMonth() + 1);
  let year = n.getYear();

  if (year > 100) year -= 100;
  if (year == 100) year += '0';

  day = day.length === 2 ? day : `0${day}`;
  month = month.length === 2 ? month : `0${month}`;

  return `${day}/${month}/${year}`;
});

Vue.filter('id', function (value, cod) {
  if (!cod) {
    return value;
  }

  return `${value} (${cod})`;
});

Vue.filter('isNullOrEmpty', function (value, newValue) {
  if (!value) {
    return newValue;
  }

  return value;
});

Vue.filter('money', function (value) {
  return formatarValorMonetario(value);
});

Vue.filter('decimalKeepPlaces', function (value, decimals) {
  return decimalKeepPlaces(value, decimals);
});

Vue.filter('numberPluralize', function (value, singularText, pluralText) {
  if (value > 1) {
    return `${value} ${pluralText}`;
  }

  return `${value} ${singularText}`;
});

Vue.filter('pluralize', function (value, singularText, pluralText) {
  if (value > 1) {
    return pluralText;
  }

  return singularText;
});

Vue.filter('phone', function (value) {
  return string.getOnlyNumbers(value);
});

Vue.filter('truncate', function (text, length, clamp) {
  clamp = clamp || '...';
  return text.length > length ? text.slice(0, length) + clamp : text;
});
